import React from "react";
import { Div, H3 } from "@cloudflare/elements";
import { Page, PageContent } from "@cloudflare/component-page";
import { Button } from "@cloudflare/component-button";
import { Form } from "@cloudflare/component-form";

type Props = {
  id: string;
};

type State = {};

const FIELDS = {
  EMAIL: "email",
  PASSWORD: "password",
  REMEMBER_ME: "remember_me",
} as const;

type FormValues = {
  [FIELDS.EMAIL]: string;
  [FIELDS.PASSWORD]: string;
  [FIELDS.REMEMBER_ME]: boolean;
};

class Login extends React.Component<Props, State> {
  state: State = {};

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.location.href = `https://${process.env.REACT_APP_DASH_URL}/oauth2/auth?response_type=code&client_id=${process.env.REACT_APP_OAUTH_APP_CLIENT_ID}&redirect_uri=https://${process.env.REACT_APP_WORKER_URL}/callback&scope=user:read&state=${this.props.id}`;
  };

  render() {
    return (
      <Page>
        <PageContent>
          <Div
            display="flex"
            flexDirection={["column", "row"]}
            maxWidth="60rem"
            mx="auto"
            mt={3}
          >
            <Div width={["100%", "60%"]} mr={[0, "5rem"]} mb={["2rem", 0]}>
              <Div mb={4}>
                <H3>Log in to Oauth</H3>
              </Div>
              <Div mb={4}>
                <Form
                  layout="horizontal"
                  onSubmit={this.handleSubmit}
                  border={false}
                >
                  <Div mt={3}>
                    <Button type="primary" submit px={5} py={3}>
                      Login
                    </Button>
                  </Div>
                </Form>
              </Div>
            </Div>
          </Div>
        </PageContent>
      </Page>
    );
  }
}

export default Login;
