import React from "react";
import { Page, PageContent } from "@cloudflare/component-page";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@cloudflare/component-table";
import CardBuilder from "@cloudflare/builder-card";

type Props = {
  id: string;
};

type State = {
  data: any;
  error: string;
};

class Info extends React.Component<Props, State> {
  render() {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    const id = queryParams.get("id");
    const username = queryParams.get("username");
    const created_on = queryParams.get("created_on");
    const modified_on = queryParams.get("modified_on");
    const twofa = queryParams.get("twofa");
    return (
      <Page>
        <PageContent>
          <CardBuilder
              title="User Info"
              description="This application has made a request to the Cloudflare staging API on your behalf. Below we have rendered your user profile information."
              table={
                <Table striped>
                  <TableHead>
                    <TableRow>
                      <TableCell>Attribute</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell fontWeight={"bold"}>Email</TableCell>
                      <TableCell>{email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={"bold"}>Id</TableCell>
                      <TableCell>{id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={"bold"}>Username</TableCell>
                      <TableCell>{username}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={"bold"}>Created On</TableCell>
                      <TableCell>{created_on}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={"bold"}>Modified On</TableCell>
                      <TableCell>{modified_on}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell fontWeight={"bold"}>Two Factor Enabled</TableCell>
                      <TableCell>{twofa ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              }
          />
        </PageContent>
      </Page>
    );
  }
}

export default Info;
