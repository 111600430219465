import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import Info from "./components/Info";
import uniqid from "uniqid";

class App extends React.Component {
  state = {
    id: "",
  };

  componentDidMount() {
    this.setState({
      id: uniqid(),
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/login">
            <Login id={this.state.id} />
          </Route>
          <Route path="/info">
            <Info id={this.state.id} />
          </Route>
          <Route path="/">
            <IndexPage />
          </Route>
        </Switch>
      </Router>
    );
  }
}

function IndexPage() {
  return (
    <header>
      <h1>test</h1>
    </header>
  );
}

export default App;
