import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { StyleProvider, createRenderer } from "@cloudflare/style-provider";

const renderer = createRenderer({
  dev: process.env.NODE_ENV !== "production",
  selectorPrefix: "oauth_",
});

ReactDOM.render(
  <StyleProvider renderer={renderer}>
    <App />
  </StyleProvider>,
  document.getElementById("root")
);
